.footer {
  background: #1C1C1C;
  padding-top: 48px;
  padding-bottom: 50px;

  &_social {
    text-align: center;
    a {
      margin: 0 15px;
    }
  }

  &_link-home {
    text-align: center;
    margin-top: 45px;
    a {
      display: flex;
      align-items: center;
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
      color: #FFFFFF;
      text-decoration: none;
      justify-content: center;

      svg {
        margin-right: 10px;
      }
    }
  }
}

@media screen and (max-width: 991px){
  .footer {
    padding-top: 24px;

    &_social a {
      margin: 0 12px;
    }

    &_link-home {
      margin-top: 18px;
    }
  }
}
