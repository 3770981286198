@import url("fonts/stylesheet.css");

body {
  font-family: 'Avenir Next Cyr', sans-serif;
  font-size: 16px;
  color: #151B1F;
  margin: 0;
  min-height: 100vh;
  scroll-behavior: smooth;
  text-rendering: optimizeSpeed;
  line-height: 1.5;
}

*,
*::before,
*::after {
  box-sizing: border-box;
  outline: none;
}


body,
h1,
h2,
h3,
h4,
p,
ul[class],
ol[class],
li{
  margin: 0;
}


a:not([class]) {
  text-decoration-skip-ink: auto;
}


img {
  max-width: 100%;
  display: block;
}


input,
button,
textarea,
select {
  font: inherit;
}


.container {
  max-width: 1145px;
  width: 100%;
  margin: 0 auto;
}

.app {
  position: relative;
  background: #F9FAFD;
}

.delimiter {
  background: #131438;
  opacity: 0.1;
  filter: blur(100px);
  width: 100%;
  height: 139px;
  z-index: 0;
  position: absolute;
  margin-top: -60px;
}

.wrapper {
  position: relative;
  background: #F9FAFD;
  z-index: 1;

  &:before {
    content: " ";
    background: url("./assets/img.png") no-repeat;
    background-size: contain;
    position: absolute;
    top: -89px;
    left: -97px;
    width: 759px;
    height: 800px;
  }
}

@media screen and (max-width: 991px){
  .wrapper:before {
    width: 463px;
    height: 488px;
    left: -171px;
    top: -21px;
  }
  .container {
    padding: 0 20px;
  }
  .delimiter {
    display: none;
  }
}
